<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ resellerPayment.name }}</span>
    </div>

    <div>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.DATE") }}</dt>
        <dd class="col-sm-8">
          {{ resellerPayment.date | moment("LLLL") }}
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INVOICES)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.INVOICE") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="resellerPayment.invoice" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.RESELLER") }}</dt>
        <dd class="col-sm-8">
          <reseller :reseller="resellerPayment.reseller" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
        <dd class="col-sm-8">
          <resellerPayment-status-badge :resellerPayment="resellerPayment" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.AMOUNT") }}</dt>
        <dd class="col-sm-8">
          {{ $formatCurrency(resellerPayment.amount) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("RESELLER_PAYMENTS.SOURCE") }}</dt>
        <dd class="col-sm-8">
          {{
            $t(
              `RESELLER_PAYMENTS.RESELLER_PAYMENT_SOURCE_${resellerPayment.source}`
            )
          }}
        </dd>
      </dl>

      <dl class="row" v-if="resellerPayment.excerpt">
        <dt class="col-sm-4">
          {{ $t("COMMON.EXCERPT") }}
        </dt>
        <dd class="col-sm-8">
          <div v-html="resellerPayment.excerpt"></div>
        </dd>
      </dl>

      <dl class="row" v-if="resellerPayment.transaction_id">
        <dt class="col-sm-4">
          {{ $t("RESELLER_PAYMENTS.TRANSACTION_ID") }}
        </dt>
        <dd class="col-sm-8">
          {{ resellerPayment.transaction_id }}
        </dd>
      </dl>

      <dl class="row" v-if="resellerPayment.transaction_data">
        <dt class="col-sm-4">
          {{ $t("RESELLER_PAYMENTS.TRANSACTION_DATA") }}
        </dt>
        <dd class="col-sm-8">
          {{ resellerPayment.transaction_data }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="resellerPayment.created_at">
          {{ resellerPayment.created_at | moment("LLLL") }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="resellerPayment.updated_at">
          {{ resellerPayment.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import ResellerPaymentStatusBadge from "./ResellerPaymentStatusBadge.vue";
export default {
  name: "resellerPayment-view-global",

  components: { ResellerPaymentStatusBadge },

  props: ["resellerPayment"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    resellerPayment(resellerPayment) {},
  },
};
</script>
